<template>
  <div>
    <h1 id="cloud-storage-header">
      Cloud Storage
    </h1>
    <div class="flex-container">
      <div
        v-for="(config, i) in connectedConfigs"
        :key="'provider'+i"
        class="flex-cell"
      >
        <div
          class="flex-item grey-background white-solid-border"
          :class="{'selectedStorageColor': connectionClicked.lastClicked === i}"
          @click="checkDoubleClick($event, configureButtonClick, config, i)"
        >
          <div class="image-container-div left-align">
            <img class="large-icon" :src="getImgUrl(displayConfigObject(config)['icon'])" :alt="displayConfigObject(config).name">
          </div>
          <h1 class="left-align provider">
            {{ displayConfigObject(config).name }}
          </h1>
          <h4 class="left-align">
            {{ config.storageName }}
          </h4>
        </div>
      </div>
      <div class="flex-cell">
        <div
          class="flex-item grey-dashed-border"
          :class="{'selectedStorageColor': connectionClicked.lastClicked === -2}"
          @click="checkDoubleClick($event, addConnectionButton, null, -2)"
        >
          <div class="image-container-div center-align">
            <img id="large-add-icon" src="@/assets/images/VasionAddGreyCircleIcon.png" alt="Add Connection">
          </div>
          <h4
            class="center-align"
          >
            Add Storage Provider
          </h4>
        </div>
      </div>
    </div>
    <md-dialog :md-active.sync="showCreateBucketDialog" @md-clicked-outside="toggleCreateBucketDialog">
      <VasionConfirmationDialog
        :message="createBucketText"
        @noButtonClick="toggleCreateBucketDialog()"
        @yesButtonClick="createBucketTestConnection()"
      />
    </md-dialog>
    <md-dialog :md-active.sync="showDeleteDialog" @md-clicked-outside="toggleEditToDelete">
      <VasionConfirmationDialog
        message="Are you sure you want to delete this connection? Vasion will no longer have access to this cloud storage or any files stored on this drive."
        @noButtonClick="toggleEditToDelete()"
        @yesButtonClick="deleteConnection()"
      />
    </md-dialog>
    <VasionGeneralModal
      :modalTitle="modalConfigEdit.modalTitle"
      :modalType="modalConfigEdit.modalType"
      :buttonGroup="modalConfigEdit.buttonGroup"
      :message="modalConfigEdit.message"
      :confirmButtonCallback="modalConfigEdit.confirmButtonCallback"
      :sync="showEditConfigurationModal"
      :confirmButtonText="modalConfigEdit.confirmButtonText"
      :rejectButtonText="activeStorageConfig.storageConfigId !== 0 ? modalConfigEdit.rejectButtonText : modalConfigEdit.backButtonText"
      :customButtonDisabled="activeStorageConfig.storageConfigId === 0"
      :customButtonText="'Delete Connection'"
      :showCustomButton="true"
      @noButtonClick="activeStorageConfig.storageConfigId !== 0 ? removeEmptyAddedConfigurations() : returnToSelectProvider()"
      @confirmButtonClick="authorizeProvider"
      @customButtonClick="toggleEditToDelete"
    >
      <div class="modal-body">
        <Loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :color="loaderColor"
          loader="dots"
          :background-color="loaderBackgroundColor"
        />
        <VasionInput
          id="Client-Description"
          v-model="activeStorageConfig.storageName"
          name="Client-Description"
          :title="'Client Connection Description'"
          placeholder="Enter Client Description..."
          inputType="top-white"
        />
        <VasionInput
          id="Client-Id"
          v-model="activeStorageConfig.clientID"
          name="Client-Id"
          :title="clientIDLabel"
          placeholder="Enter Client ID..."
          inputType="top-white"
          @input="updateRedirectURI"
        />
        <VasionInput
          id="Client-Secret"
          v-model="activeStorageConfig.clientSecret"
          name="Client-Secret"
          :title="clientSecretLabel"
          placeholder="Enter Client Secret..."
          inputType="top-white"
        />
        <VasionInput
          v-if="!isActiveStorageWorkDocs && !isActiveStorageAmazonS3 && !isActiveStorageWasabi"
          id="oAuth-URL"
          v-model="activeStorageConfig.oAuthEndpoint"
          name="oAuth-URL"
          title="oAuth URL"
          inputType="top-white"
          :placeholder="oAuthURL"
          :isDisabled="true"
        />
        <VasionInput
          v-if="!isActiveStorageWorkDocs && !isActiveStorageAmazonS3 && !isActiveStorageWasabi"
          id="Client-URI"
          v-model="displayClientURI"
          name="Client-URI"
          title="Client URI"
          inputType="top-white"
          :isDisabled="true"
          :placeholder="'Enter Client ID to View Callback URI'"
        />
        <VasionInput
          v-if="isActiveStorageWorkDocs || isActiveStorageAmazonS3 || isActiveStorageWasabi"
          id="Client-Region"
          v-model="activeStorageConfig.region"
          name="Client-Region"
          title="AWS Region"
          placeholder="Enter Region..."
          inputType="top-white"
        />
        <VasionInput
          v-if="isActiveStorageWorkDocs"
          id="Client-DirectoryID"
          v-model="activeStorageConfig.directoryID"
          name="Client-DirectoryID"
          title="DirectoryID"
          placeholder="Enter Directory ID..."
          inputType="top-white"
        />
        <VasionInput
          v-if="isActiveStorageAmazonS3 || isActiveStorageWasabi"
          id="Client-BucketName"
          v-model="activeStorageConfig.bucketName"
          name="Client-BucketName"
          title="Bucket Name"
          placeholder="Enter Name..."
          inputType="top-white"
        />
        <VasionCheckbox
          id="data-automation-checkbox"
          name="data-automation-checkbox"
          :checked="activeStorageConfig.forDataAutomationOnly"
          @change="handleDataAutomationCheckbox()"
        >
          <div>
            <span>Use for Data Automation Only</span>
            <small style="display: block;">Storage connection to be used for Import/Export in the Automation App</small>
          </div>
        </VasionCheckbox>
      </div>
    </VasionGeneralModal>
    <VasionSnackbar
      id="varified-connection-snack"
      :showSnackbarBool.sync="snackbarConfig.bool"
      :snackbarImage="snackbarConfig.image"
      :snackbarSubTitle="snackbarConfig.subTitle"
      :snackbarTitle="snackbarConfig.title"
    />
    <VasionGeneralModal
      :modalTitle="modalConfigSelectNew.modalTitle"
      :modalType="modalConfigSelectNew.modalType"
      :buttonGroup="modalConfigSelectNew.buttonGroup"
      :message="modalConfigSelectNew.message"
      :confirmButtonCallback="modalConfigSelectNew.confirmButtonCallback"
      :sync="showConnectModal"
      :confirmButtonText="modalConfigSelectNew.confirmButtonText"
      :rejectButtonText="modalConfigSelectNew.rejectButtonText"
      @noButtonClick="showConnectModal = !showConnectModal"
      @confirmButtonClick="connectNewProvider"
    >
      <div class="connectCloudModal flex-container">
        <div
          v-for="provider, key in displayData"
          :key="key"
          class="flex-cell-small grey-background"
          :class="{'storageProviderBorder': true, 'selectedStorageColor': modalSelectedCloudStorage === storageTypes[key]}"
          @click="modalChangeSelectedStorage(storageTypes[key])"
        >
          <div class="center-align">
            <div class="image-container-div center-align">
              <img class="image-center" :src="getImgUrl(provider['icon'])" :alt="provider.name">
            </div>
            <h2 class="provider">
              {{ provider.name }}
            </h2>
          </div>
        </div>
      </div>
    </VasionGeneralModal>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import VasionCheckbox from '../../../components/shared/VasionCheckbox.vue';

export default {
  name: 'CloudStorageSetup',
  components: {
    Loading,
    VasionCheckbox,
  },
  data: function () {
    return {
      activeStorageConfig: {
        clientID: '',
        clientSecret: '',
        hasToken: true,
        oAuthEndpoint: '',
        redirectURI: '',
        storageType: '',
        region: '',
        directoryID: '',
      },
      blankConfigurations: {},
      connectedConfigs: [],
      connectionClicked: {
        connection: -1,
        delay: 700,
        timer: null,
        lastClicked: -1,
      },
      displayData: {
        box: {
          key: 'box',
          icon: 'boxIconLarge.png',
          name: 'Box',
        },
        google: {
          key: 'google',
          icon: 'googleDriveIconLarge.png',
          name: 'Google Drive',
        },
        oneDrive: {
          key: 'oneDrive',
          icon: 'oneDriveIconLarge.png',
          name: 'OneDrive',
        },
        workDocs: {
          key: 'workDocs',
          icon: 'workDocsIconLarge.png',
          name: 'WorkDocs',
        },
        s3: {
          key: 's3',
          icon: 'S3Large.png',
          name: 'Amazon S3',
        },
        wasabi: {
          key: 'wasabi',
          icon: 'wasabiIconLarge.png',
          name: 'Wasabi',
        },
      },
      fullPage: true,
      isLoading: false,
      isSignIn: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      modalConfigEdit: {
        confirmButtonCallback: this.authorizeProvider,
        confirmButtonText: 'Authorize',
        modalTitle: 'Edit Cloud Storage',
        modalType: 'slot',
        rejectButtonText: 'Cancel',
        backButtonText: 'Back',
      },
      modalConfigSelectNew: {
        confirmButtonCallback: this.connectNewProvider,
        confirmButtonText: 'Continue',
        modalTitle: 'Select Cloud Storage Provider',
        modalType: 'slot',
        rejectButtonText: 'Cancel',
      },
      modalSelectedCloudStorage: '',
      redirectURI: '',
      showConnectModal: false,
      showEditConfigurationModal: false,
      showCreateBucketDialog: false,
      showDeleteDialog: false,
      snackbarConfig: {
        image: false,
        bool: false,
        subTitle: '',
        title: '',
      },
      storageTypes: {
        box: 'Box',
        google: 'GoogleDrive',
        oneDrive: 'OneDrive',
        workDocs: 'WorkDocs',
        s3: 'S3',
        wasabi: 'Wasabi',
      },
    }
  },
  computed: {
    clientIDLabel() {
      if (this.isActiveStorageWorkDocs || this.isActiveStorageAmazonS3) {
        return 'AWS Access Key ID'
      } else if ( this.isActiveStorageWasabi ) {
        return 'Access Key ID'
      }
      return'Client ID'
    },
    clientSecretLabel() {
      if (this.isActiveStorageWorkDocs || this.isActiveStorageAmazonS3) {
        return 'AWS Secret Key'
      } else if (this.isactiveStorageWasabi) {
        return 'Secret Key'
      }
      return 'Client Secret'
    },
    createBucketText() { return `The S3 Bucket, ${this.activeStorageConfig.bucketName}, was not found. Do you want to create this bucket?`},
    displayClientURI() {
      if (this.activeStorageConfig.clientID !== "") {
        return this.activeStorageConfig.redirectURI
      }
      return ""
    },
    isActiveStorageBox() {
      return this.activeStorageConfig.storageType === 'Box'
    },
    isActiveStorageGoogleDrive() {
      return this.activeStorageConfig.storageType === 'GoogleDrive'
    },
    isActiveStorageOneDrive() {
      return this.activeStorageConfig.storageType === 'OneDrive'
    },
    isActiveStorageWorkDocs() {
      return this.activeStorageConfig.storageType === 'WorkDocs'
    },
    isActiveStorageAmazonS3() {
      return this.activeStorageConfig.storageType === 'S3'
    },
    isActiveStorageWasabi() {
      return this.activeStorageConfig.storageType === 'Wasabi'
    },
    oAuthURL() { return `${this.activeStorageConfig.oAuthURLBase}${this.activeStorageConfig.clientId}&redirect_uri=${this.activeStorageConfig.redirectURI}` },
    thirdPartyStorageConfigurations() { return this.$store.state.storage.thirdPartyStorageConfigurations },
    thirdPartyStorageConfigurationTypes() { return this.$store.state.storage.thirdPartyStorageConfigurationTypes },
  },
   watch: {
    thirdPartyStorageConfigurations: function () {
      this.connectedConfigs = []

      this.thirdPartyStorageConfigurations.map((element) => {
        let tempClientID = ''
        switch (element.storageType) {
          case this.storageTypes.google:
          case this.storageTypes.oneDrive:
          case this.storageTypes.box:
          case this.storageTypes.workDocs:
          case this.storageTypes.s3:
          case this.storageTypes.wasabi:
            this.connectedConfigs.push(element)
            //Format redirect URI to not have "." in path.
            tempClientID = this.connectedConfigs[this.connectedConfigs.length - 1].clientID.replace(/\./g, '_')
            this.connectedConfigs[this.connectedConfigs.length - 1].redirectURI = `${this.redirectURI}/${tempClientID}`
            //Mark the configuration as coming from the database so we don't delete it.
            this.connectedConfigs[this.connectedConfigs.length - 1].isAuthorized = true
            break;
          default:
            console.warn("Unknown provider given")
            break;
        }
        return true
      })
    },
    thirdPartyStorageConfigurationTypes: function () {
      this.blankConfigurations = {}
      this.thirdPartyStorageConfigurationTypes.map((element) => {
        switch (element.storageType) {
          case this.storageTypes.google:
          case this.storageTypes.oneDrive:
          case this.storageTypes.box:
          case this.storageTypes.workDocs:
          case this.storageTypes.s3:
          case this.storageTypes.wasabi:
            this.blankConfigurations[element.storageType] = element
            break;
          default:
            console.warn("Unknown provider given")
            break;
        }
        return true
      })
    }
  },
  created: async function () {
    await this.loadConfigurationsAndCheckForAuthentication()
  },
  methods: {
    async addConnectionButton() {
      this.toggleAddModal()
      await this.$store.dispatch('storage/getAvailableThirdPartyStorageConfigurationTypes')
    },
    areConfigurationFieldsFilled() {
      if (this.isActiveStorageWorkDocs && !this.activeStorageConfig.directoryID)
        return false
      if ((this.isActiveStorageAmazonS3 || this.isActiveStorageWasabi || this.isActiveStorageWorkDocs) && !this.activeStorageConfig.region)
        return false
      if ((this.isActiveStorageAmazonS3 || this.isActiveStorageWasabi) && !this.activeStorageConfig.bucketName)
        return false
      if (this.activeStorageConfig.clientID &&
        this.activeStorageConfig.clientSecret &&
        this.activeStorageConfig.storageName
      )
        return true
      return false
    },
    async authorizeProvider() {
      this.isLoading = true
      if (!this.areConfigurationFieldsFilled()) {
        this.snackbarConfig.title = 'Save Failure'
        this.snackbarConfig.subTitle = 'Please fill out all available fields'
        this.snackbarConfig.image = false
        this.snackbarConfig.bool = true
        this.isLoading = false
        return
      }

      if ((this.isActiveStorageAmazonS3 || this.isActiveStorageWasabi) && !this.validateBucketName(this.activeStorageConfig.bucketName)){
        this.snackbarConfig.title = 'Save Failure'
        this.snackbarConfig.subTitle = 'Invalid S3 Bucket Name'
        this.snackbarConfig.image = false
        this.snackbarConfig.bool = true
        this.isLoading = false
        return
      }

      if ((this.isActiveStorageAmazonS3 || this.isActiveStorageWasabi) &&
        (this.activeStorageConfig.clientID !== '' ||
        this.activeStorageConfig.clientSecret !== '' ||
        this.activeStorageConfig.region !== '')) {
        this.activeStorageConfig.createBucket = false
        const validCredentials = await this.$store.dispatch('storage/testAmazonStorageConnection', this.activeStorageConfig)
        if (validCredentials.Value === 'FAIL') {
          this.snackbarConfig.title = 'Save Failure'
          this.snackbarConfig.subTitle = 'Invalid S3 Configuration'
          this.snackbarConfig.image = false
          this.snackbarConfig.bool = true
          this.isLoading = false
          return
        } else if (validCredentials.Value === 'NOT_EXISTS') {
          this.toggleCreateBucketDialog()
          this.isLoading = false
          return
        } else if (validCredentials.Value === 'SUCCESS') {
            //Continue
        }
      }
      await this.checkAndSaveConnection()
    },
    async boxSignIn(endpoint) {
      const redirectURL = `${endpoint}authorize?client_id=${this.activeStorageConfig.clientID}&redirect_uri=${this.activeStorageConfig.redirectURI}&scope=root_readwrite&response_type=code`

      window.location.replace(encodeURI(redirectURL))
    },
    checkDoubleClick(event, callbackFunc, params, i) {
      // -1 = no click
      // -2 = add storage provider
      // all non negative integers = an existing provider
      this.connectionClicked.lastClicked = i
      clearTimeout(this.connectionClicked.timer)
      if (this.connectionClicked.connection !== i) {
        this.connectionClicked.connection = i
        this.connectionClicked.timer = setTimeout( () => {
          this.connectionClicked.connection = -1
        }, this.connectionClicked.delay)
        this.connectionClicked.connection = i
      } else if (this.connectionClicked.connection === i) {
        this.connectionClicked.connection = -1
        callbackFunc(params)
      }
    },
    async checkAndSaveConnection() {
      if (this.isActiveStorageAmazonS3 || this.isActiveStorageWasabi) {
        let existingConfig = this.connectedConfigs.find(config => {
          return config.clientID === this.activeStorageConfig.clientID && config.storageConfigId !== this.activeStorageConfig.storageConfigId && config.storageType === this.activeStorageConfig.storageType && this.activeStorageConfig.bucketName === config.bucketName
        })
        if (existingConfig){
          this.snackbarConfig.title = 'Save Failure'
          this.snackbarConfig.subTitle = 'AWS Access Key ID and Bucket Name are already in use'
          this.snackbarConfig.image = false
          this.snackbarConfig.bool = true
          this.isLoading = false
          return
        }
      } else {
        let existingConfig = this.connectedConfigs.find(config => {
          return config.clientID === this.activeStorageConfig.clientID && config.storageConfigId !== this.activeStorageConfig.storageConfigId && config.storageType === this.activeStorageConfig.storageType
        })
        if (existingConfig){
          this.snackbarConfig.title = 'Save Failure'
          this.snackbarConfig.subTitle = 'Client ID already in use'
          this.snackbarConfig.image = false
          this.snackbarConfig.bool = true
          this.isLoading = false
          return
        }
      }

      const saved = await this.$store.dispatch('storage/setThirdPartyStorageConfiguration', this.activeStorageConfig)

      if (!saved || saved.Value !== 'True') {
        this.snackbarConfig.title = 'Save Failure'
        this.snackbarConfig.subTitle = 'Unable to save settings'
        this.snackbarConfig.image = false
        this.snackbarConfig.bool = true
        this.isLoading = false
        return
      }

      const { storageType } = this.activeStorageConfig

      let endpoint = this.activeStorageConfig.oAuthEndpoint
      if (!endpoint.endsWith('/')) {
        endpoint += '/'
      }

      switch (storageType) {
        case this.storageTypes.google:
          this.googleDriveSignIn(endpoint)
          break;
        case this.storageTypes.oneDrive:
          this.oneDriveSignIn(endpoint)
          break;
        case this.storageTypes.box:
          this.boxSignIn(endpoint)
          break;
        case this.storageTypes.workDocs:
        case this.storageTypes.s3:
        case this.storageTypes.wasabi:
          this.showSettingsSavedSnackbar()
          break;
        default:
          this.isLoading = false
          break;
      }
    },
    configureButtonClick(config) {
      this.updateSelectedProvider(config)
      this.toggleEditModal()
      this.modalConfigEdit.modalTitle = `Connect ${this.displayConfigObject(config).name}`
      this.modalConfigEdit.confirmButtonText = (this.isActiveStorageWorkDocs || this.isActiveStorageAmazonS3 || this.isActiveStorageWasabi) ? 'Save' : 'Authorize'
      if (config.storageConfigId === 0){
        this.modalConfigEdit.confirmButtonText = 'Finish Setup'
      }
    },
    async connectNewProvider() {
      if (this.modalSelectedCloudStorage === '') {
        this.snackbarConfig.title = 'Connect Failure'
        this.snackbarConfig.subTitle = 'No Provider Selected'
        this.snackbarConfig.image = false
        this.snackbarConfig.bool = true
        return
      }

      //Add new connection
      this.connectedConfigs.push(this.blankConfigurations[this.modalSelectedCloudStorage])
      this.connectedConfigs[this.connectedConfigs.length - 1].isAuthorized = false

      //Continue here setting up configuration modal
      this.toggleAddModal()
      this.configureButtonClick(this.blankConfigurations[this.modalSelectedCloudStorage])
    },
    async createBucketTestConnection() {
      this.toggleCreateBucketDialog()
      this.isLoading = true
      this.activeStorageConfig.createBucket = true
      const createCredentials = await this.$store.dispatch('storage/testAmazonStorageConnection', this.activeStorageConfig)
      if (createCredentials.Value === 'FAIL') {
        this.snackbarConfig.title = 'Save Failure'
        this.snackbarConfig.subTitle = 'Invalid S3 Configuration'
        this.snackbarConfig.image = false
        this.snackbarConfig.bool = true
        this.isLoading = false
        return
      } else if (createCredentials.Value === 'FAILED_TO_CREATE') {
        this.snackbarConfig.title = 'Save Failure'
        this.snackbarConfig.subTitle = 'Failed to create S3 Bucket'
        this.snackbarConfig.image = false
        this.snackbarConfig.bool = true
        this.isLoading = false
        return
      } else if (createCredentials.Value === 'SUCCESS') {
        await this.checkAndSaveConnection()
      }
    },
    async deleteConnection() {
      const response = await this.$store.dispatch('storage/deleteThirdPartyStorageConfiguration', this.activeStorageConfig.storageConfigId)
      if (response.Value) {
        await this.loadConfigurationsAndCheckForAuthentication()
        this.showEditConfigurationModal = false
        this.snackbarConfig.title = 'Success'
        this.snackbarConfig.subTitle = 'Cloud Storage Provider successfully deleted'
        this.snackbarConfig.image = true
        this.snackbarConfig.bool = true
      } else {
        this.snackbarConfig.title = 'Deletion Failure'
        this.snackbarConfig.subTitle = 'Connection could not be deleted'
        this.snackbarConfig.image = false
        this.snackbarConfig.bool = true
      }
      this.toggleDeleteDialog()
    },
    displayConfigObject(config){
      let keys = Object.keys(this.storageTypes)
      let displayObject = Object()
      for (let i = 0; i < keys.length; i++) {
        if (config.storageType.toLowerCase() === keys[i].toLowerCase() || config.storageType === this.storageTypes[keys[i]]){
          displayObject = this.displayData[keys[i]]
        }
      }
      return displayObject
    },
    getImgUrl(path) {
      var images = require(`@/assets/images/${path}`)
      return images
    },
    googleDriveSignIn(endpoint) {
      const redirectURL = `${endpoint}auth?access_type=offline&prompt=consent&client_id=${this.activeStorageConfig.clientID}&redirect_uri=${this.activeStorageConfig.redirectURI}&scope=https://www.googleapis.com/auth/drive&response_type=code`

      window.location.replace(encodeURI(redirectURL))
    },
    async handleDataAutomationCheckbox() {
      this.activeStorageConfig.forDataAutomationOnly = !this.activeStorageConfig.forDataAutomationOnly
    },
    async loadConfigurationsAndCheckForAuthentication() {
      await this.$store.dispatch('storage/getThirdPartyStorageConfigurations')

      this.redirectURI = window.location.href
      const routeName = 'storageSetup/cloudStorage'
      const indexOf = this.redirectURI.indexOf(routeName)
      if (indexOf >= 0) {
        this.redirectURI = this.redirectURI.substring(0, indexOf + routeName.length)
      }

      if (this.$route.query.error) {
        this.snackbarConfig.title = 'Authentication Failure'
        this.snackbarConfig.subTitle = 'You were unable to authenticate'
        this.snackbarConfig.image = false
        this.snackbarConfig.bool = true
        return
      }

      const thirdPartyCode = this.$route.query.code
      const fromApp = this.$route.params.storageType

      if (!thirdPartyCode || !fromApp) {
        return
      }
      let config = this.connectedConfigs.find(config => {
        return `${this.redirectURI}/${fromApp}` === `${this.redirectURI}${config.redirectURI}`
      })
      const thirdPartyCodePayload = {
        storageConfigId: config.storageConfigId,
        code: thirdPartyCode,
      }
      const setCodeResult = await this.$store.dispatch('admin/setThirdPartyCode', thirdPartyCodePayload)
      if (setCodeResult && setCodeResult.Value === 'True') {
        this.snackbarConfig.title = 'Authentication Success!'
        this.snackbarConfig.subTitle = 'You have successfully authenticated!'
        this.snackbarConfig.image = true

        this.$store.dispatch('storage/getThirdPartyStorageConfigurations')
      } else {
        this.snackbarConfig.title = 'Authentication Failure'
        this.snackbarConfig.subTitle = 'You were unable to authenticate'
        this.snackbarConfig.image = false
      }

      this.snackbarConfig.bool = true
    },
    async oneDriveSignIn(endpoint) {
      const redirectURL = `${endpoint}authorize?client_id=${this.activeStorageConfig.clientID}&redirect_uri=${this.activeStorageConfig.redirectURI}&scope=Files.ReadWrite.All Sites.ReadWrite.All offline_access&response_type=code`

      window.location.replace(encodeURI(redirectURL))
    },
    modalChangeSelectedStorage(storage) {
      if (this.modalSelectedCloudStorage === storage) {
        this.modalSelectedCloudStorage = ''
      } else {
        this.modalSelectedCloudStorage = storage
      }
    },
    removeEmptyAddedConfigurations(){
      if (!this.activeStorageConfig.isAuthorized) {
        this.connectedConfigs.pop()
      }
      this.toggleEditModal()
    },
    returnToSelectProvider() {
      this.toggleAddModal()
      this.toggleEditModal()
      this.connectedConfigs.pop()
    },
    showSettingsSavedSnackbar() {
      this.isLoading = false

      this.snackbarConfig.title = 'Success'
      this.snackbarConfig.subTitle = 'Settings saved!'
      this.snackbarConfig.image = true
      this.snackbarConfig.bool = true

      this.showEditConfigurationModal = false
    },
    toggleAddModal() {
      this.showConnectModal = !this.showConnectModal
    },
    toggleCreateBucketDialog() {
      this.showCreateBucketDialog = !this.showCreateBucketDialog
      this.toggleEditModal()
    },
    toggleDeleteDialog() {
      this.showDeleteDialog = !this.showDeleteDialog
    },
    toggleEditModal() {
      this.showEditConfigurationModal = !this.showEditConfigurationModal
    },
    toggleEditToDelete() {
      this.toggleEditModal()
      this.toggleDeleteDialog()
    },
    updateRedirectURI() {
      let tempClientID = this.activeStorageConfig.clientID.replace(/\./g, '_')
      this.activeStorageConfig.redirectURI = `${this.redirectURI}/${tempClientID}`
    },
    updateSelectedProvider(provider) {
      this.activeStorageConfig = this.thirdPartyStorageConfigurations.find(p => p.storageConfigId === provider.storageConfigId) ? this.thirdPartyStorageConfigurations.find(p => p.storageConfigId === provider.storageConfigId) : this.connectedConfigs[this.connectedConfigs.length - 1]
      this.activeStorageConfig.clientID = provider.clientID
      this.updateRedirectURI()
    },
    validateBucketName(bucketName) {
      let validBucketCharacters = 'abcdefghijklmnopqrstuvwxyz1234567890'
      if (bucketName === '' || bucketName === null || bucketName === undefined){
        bucketName = ''
        return true
      }
      else if (bucketName.length < 3 || bucketName.length > 63) return false
      else if (!validBucketCharacters.includes(bucketName[0])) return false
      else if (!validBucketCharacters.includes(bucketName[bucketName.length - 1])) return false
      validBucketCharacters += ".-"
      for (let i = 0; i < bucketName.length; i++) {
        if (!validBucketCharacters.includes(bucketName[i])){
          return false
        }
      }
      return true
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .center-align{
    margin: auto;
    text-align: center;
  }
  .left-align{
    width: 360px;
    height: 20px;
  }
  #cloud-storage-header {
    margin: 22px 0px 24px 16px;
  }
  .connectCloudModal{
    margin: 10px;
  }
  div.vasion-input input {
    background-color: $grey-75;
  }
  .flex-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: 15px;
  }
  .flex-container:nth-of-type(1) {
      padding-left: 5px;
  }

  .flex-cell {
    flex: 0 0 33%;
    display: flex;
    padding: 5px;
    box-sizing: border-box;
    height: 250px;
  }

  .flex-cell-small {
    flex: 0 0 20%;
    padding: 20px;
    box-sizing: border-box;
    height: 300px;
  }

  .flex-item {
    flex-grow: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .grey-background{
    background-color: $grey-50;
  }

  .grey-dashed-border{
    border: 1px dashed;
    border-radius: 8px;
    border-color: $grey-100;
  }

  .storageProviderBorder{
    border: 1px solid;
    border-radius: 8px;
    border-color: $white;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }

  .white-solid-border{
    border: 1px solid $white;
  }

  .selectedStorageColor{
    border-color: $orange-300;
    background-color: $orange-50;
  }

  .vasion-checkbox {
    margin-top: 16px;
  }

  .vasion-input:not(:first-of-type) {
    margin-top: 16px;
  }
  .image-container-div {
    max-width: 150px;
    height: 150px;
  }
  .large-icon {
    height: 100px;
  }
  .modal-body {
    width: 650px;
    height: 450px;
    padding-top: 16px;
  }

</style>
